import { AnyObject } from '@movecloser/front-core'
import { AsyncComponent } from 'vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const SIGNUP_FORM_COMPONENT_KEY = 'SignupFormComponent'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum SignupComponentVariant {
  General = 'general',
  Simplified = 'simplified'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const signupComponentVariants: Record<string, AsyncComponent> = {
  [SignupComponentVariant.General]: () => import(/* webpackChunkName: "profile/SignupGeneral" */ './variants/SignupGeneral.vue'),
  [SignupComponentVariant.Simplified]: () => import(/* webpackChunkName: "profile/SignupSimplified" */ './variants/SignupSimplified.vue')
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultComponentConfig: AnyObject = {
  consents: {
    group: false,
    options: []
  },
  input: {
    animatedLabel: true
  },
  variant: SignupComponentVariant.General,
  isAlertUnderForm: false,
  socials: {
    display: true,
    exclude: []
  }
}
